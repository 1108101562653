import React from 'react';
import { IconProps } from './types';

const IconPerson: React.FC<IconProps> = props => (
  <svg 
    {...props} 
    aria-labelledby='person'
    role='img'
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 12C0.25 5.51527 5.51528 0.25 12 0.25C18.4847 0.25 23.75 5.51527 23.75 12C23.75 15.3328 22.3597 18.3429 20.1272 20.4827C18.0161 22.5062 15.1527 23.75 12 23.75C8.84731 23.75 5.98386 22.5062 3.87278 20.4827L3.70753 20.6551L3.87278 20.4827C1.64028 18.3429 0.25 15.3328 0.25 12ZM12 1.47043C6.18881 1.47043 1.47043 6.18881 1.47043 12C1.47043 14.624 2.43286 17.0255 4.02315 18.8695L4.21704 19.0943L4.40558 18.865C5.53833 17.4873 7.03926 16.4227 8.75609 15.8222L9.18467 15.6723L8.82882 15.3903C7.64641 14.4532 6.88828 13.0059 6.88828 11.3832C6.88828 8.56216 9.17893 6.27151 12 6.27151C14.8211 6.27151 17.1117 8.56216 17.1117 11.3832C17.1117 13.0059 16.3536 14.4532 15.1712 15.3903L14.8153 15.6723L15.2439 15.8222C16.9607 16.4227 18.4617 17.4873 19.5944 18.865L19.783 19.0943L19.9768 18.8695C21.5671 17.0255 22.5296 14.624 22.5296 12C22.5296 6.18881 17.8112 1.47043 12 1.47043ZM12 7.49194C9.85247 7.49194 8.10871 9.23569 8.10871 11.3832C8.10871 13.5308 9.85254 15.2737 12 15.2737C14.1475 15.2737 15.8913 13.5308 15.8913 11.3832C15.8913 9.23569 14.1475 7.49194 12 7.49194ZM12 16.4941C9.26569 16.4941 6.82747 17.7721 5.25013 19.7612L5.09813 19.9529L5.28648 20.109C7.10936 21.62 9.44917 22.5296 12 22.5296C14.5508 22.5296 16.8906 21.62 18.7135 20.109L18.9019 19.9529L18.7499 19.7612C17.1725 17.7721 14.7343 16.4941 12 16.4941Z"/>
  </svg>
);

export default IconPerson;

/*    
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3651 20.6417C18.2094 22.708 15.2847 23.9785 12.0649 23.9785C8.84514 23.9785 5.92041 22.708 3.76471 20.6417C3.06491 19.8785 3.93561 19.0604 4.2774 18.6847C5.43869 17.2723 6.97761 16.1806 8.73847 15.5647C7.4989 14.5824 6.7032 13.0641 6.7032 11.3617C6.7032 8.40258 9.10579 6 12.0649 6C15.0241 6 17.4266 8.40258 17.4266 11.3617C17.4266 13.0641 16.6309 14.5824 15.3914 15.5647C17.1522 16.1806 18.6912 17.2723 19.8525 18.6847C20.5649 19.3785 20.7725 20.2512 20.3651 20.6417ZM12.0649 22.2581C9.5746 22.2581 7.29073 21.3703 5.51095 19.8951C7.04299 17.963 9.4103 16.7226 12.0649 16.7226C14.7196 16.7226 17.0869 17.963 18.6189 19.8951C16.8391 21.3703 14.5552 22.2581 12.0649 22.2581ZM15.7062 11.3617C15.7062 13.3712 14.0744 15.0021 12.0649 15.0021C10.0555 15.0021 8.42364 13.3712 8.42364 11.3617C8.42364 9.35226 10.0555 7.72043 12.0649 7.72043C14.0744 7.72043 15.7062 9.35226 15.7062 11.3617Z"/>
    <path d="M12.0037 3.35908e-06C9.48125 -0.00188386 7.02243 0.791488 4.97681 2.26731C2.93119 3.74314 1.40289 5.8263 0.609222 8.22061C-0.184449 10.6149 -0.203099 13.1985 0.555923 15.604C1.31494 18.0095 2.813 20.1145 4.8371 21.6197C6.90687 23.1704 9.42344 24.0086 12.0097 24.0086C14.5959 24.0086 17.1125 23.1704 19.1822 21.6197C21.2012 20.1108 22.6939 18.0046 23.4489 15.5998C24.2038 13.195 24.1827 10.6136 23.3884 8.22149C22.5941 5.82939 21.0671 3.74798 19.0236 2.27233C16.9802 0.796688 14.5242 0.00167712 12.0037 3.35908e-06ZM12.0037 4.80172C12.7159 4.80172 13.4122 5.01294 14.0044 5.40865C14.5967 5.80437 15.0583 6.36681 15.3308 7.02486C15.6034 7.68291 15.6747 8.40701 15.5358 9.10559C15.3968 9.80417 15.0538 10.4459 14.5502 10.9495C14.0465 11.4532 13.4048 11.7961 12.7062 11.9351C12.0077 12.0741 11.2836 12.0027 10.6255 11.7302C9.96747 11.4576 9.40502 10.996 9.00931 10.4038C8.61359 9.81155 8.40238 9.11528 8.40238 8.40301C8.40523 7.44877 8.78557 6.53442 9.46032 5.85966C10.1351 5.18491 11.0494 4.80457 12.0037 4.80172ZM12.0037 21.6077C9.5225 21.6055 7.13878 20.6416 5.35329 18.9188C5.88432 17.5888 6.8013 16.4483 7.98616 15.644C9.17102 14.8397 10.5695 14.4085 12.0016 14.4059C13.4336 14.4034 14.8336 14.8295 16.0214 15.6295C17.2091 16.4295 18.1302 17.5667 18.6661 18.8948C16.8847 20.6343 14.4935 21.608 12.0037 21.6077Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.69979 20.7632C1.42022 18.5783 0 15.5039 0 12.1C0 5.4772 5.3772 0.0999985 12 0.0999985C18.6228 0.0999985 24 5.4772 24 12.1C24 15.5039 22.5798 18.5783 20.3002 20.7632C18.1445 22.8295 15.2198 24.1 12 24.1C8.78022 24.1 5.85548 22.8295 3.69979 20.7632ZM19.7875 18.8062C21.3402 17.0058 22.2796 14.6617 22.2796 12.1C22.2796 6.42688 17.6731 1.82043 12 1.82043C6.32688 1.82043 1.72043 6.42688 1.72043 12.1C1.72043 14.6617 2.65978 17.0058 4.21247 18.8062C5.37376 17.3938 6.91269 16.3021 8.67355 15.6862C7.43398 14.7039 6.63828 13.1856 6.63828 11.4832C6.63828 8.52408 9.04086 6.1215 12 6.1215C14.9591 6.1215 17.3617 8.52408 17.3617 11.4832C17.3617 13.1856 16.566 14.7039 15.3265 15.6862C17.0873 16.3021 18.6262 17.3938 19.7875 18.8062ZM5.44602 20.0166C7.22581 21.4918 9.50968 22.3796 12 22.3796C14.4903 22.3796 16.7742 21.4918 18.554 20.0166C17.0219 18.0845 14.6546 16.8441 12 16.8441C9.34538 16.8441 6.97806 18.0845 5.44602 20.0166ZM12 15.1237C14.0095 15.1237 15.6413 13.4927 15.6413 11.4832C15.6413 9.47376 14.0095 7.84193 12 7.84193C9.99054 7.84193 8.35871 9.47376 8.35871 11.4832C8.35871 13.4927 9.99054 15.1237 12 15.1237Z"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 12.6C0.5 5.9774 5.87741 0.599998 12.5 0.599998C19.1226 0.599998 24.5 5.9774 24.5 12.6C24.5 16.0036 23.0805 19.0774 20.8001 21.2632C18.6437 23.3301 15.7197 24.6 12.5 24.6C9.28025 24.6 6.35626 23.3301 4.19993 21.2632L3.85007 21.6282L4.19993 21.2632C1.91949 19.0774 0.5 16.0036 0.5 12.6ZM12.5 1.35175C6.29208 1.35175 1.25175 6.39207 1.25175 12.6C1.25175 15.403 2.28011 17.9688 3.97878 19.9385L4.38342 20.4078L4.7769 19.9292C5.9291 18.5277 7.45565 17.4452 9.20118 16.8346L10.0956 16.5218L9.35298 15.9333C8.17878 15.0027 7.4269 13.5664 7.4269 11.9564C7.4269 9.15676 9.70035 6.88331 12.5 6.88331C15.2996 6.88331 17.5731 9.15676 17.5731 11.9564C17.5731 13.5664 16.8212 15.0027 15.647 15.9333L14.9044 16.5218L15.7988 16.8346C17.5444 17.4452 19.0709 18.5277 20.2231 19.9292L20.6166 20.4078L21.0212 19.9385C22.7199 17.9688 23.7482 15.403 23.7482 12.6C23.7482 6.39207 18.7079 1.35175 12.5 1.35175ZM12.5 7.63506C10.115 7.63506 8.17865 9.57143 8.17865 11.9564C8.17865 14.3415 10.1152 16.2769 12.5 16.2769C14.8848 16.2769 16.8213 14.3415 16.8213 11.9564C16.8213 9.57143 14.885 7.63506 12.5 7.63506ZM12.5 17.0286C9.56365 17.0286 6.94543 18.4013 5.25226 20.5366L4.93504 20.9366L5.32811 21.2624C7.2752 22.8764 9.77512 23.8482 12.5 23.8482C15.2249 23.8482 17.7248 22.8764 19.6719 21.2624L20.065 20.9366L19.7477 20.5366C18.0546 18.4013 15.4363 17.0286 12.5 17.0286Z" fill="black"/>

    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 12.1C0.5 5.75334 5.65335 0.599998 12 0.599998C18.3467 0.599998 23.5 5.75334 23.5 12.1C23.5 15.3618 22.1397 18.3076 19.9542 20.4023C17.8878 22.383 15.0856 23.6 12 23.6C8.91441 23.6 6.11224 22.383 4.04577 20.4023L3.71048 20.7521L4.04577 20.4023C1.86035 18.3076 0.5 15.3618 0.5 12.1ZM12 1.32043C6.05074 1.32043 1.22043 6.15074 1.22043 12.1C1.22043 14.7862 2.20593 17.2451 3.83383 19.1328L4.22161 19.5824L4.5987 19.1238C5.70289 17.7807 7.16583 16.7433 8.83863 16.1582L9.69578 15.8584L8.9841 15.2944C7.85883 14.4026 7.13828 13.0262 7.13828 11.4832C7.13828 8.80023 9.317 6.6215 12 6.6215C14.683 6.6215 16.8617 8.80023 16.8617 11.4832C16.8617 13.0262 16.1412 14.4026 15.0159 15.2944L14.3042 15.8584L15.1614 16.1582C16.8342 16.7433 18.2971 17.7807 19.4013 19.1238L19.7784 19.5824L20.1662 19.1328C21.7941 17.2451 22.7796 14.7862 22.7796 12.1C22.7796 6.15074 17.9493 1.32043 12 1.32043ZM12 7.34193C9.7144 7.34193 7.85871 9.19762 7.85871 11.4832C7.85871 13.769 9.71455 15.6237 12 15.6237C14.2855 15.6237 16.1413 13.769 16.1413 11.4832C16.1413 9.19762 14.2856 7.34193 12 7.34193ZM12 16.3441C9.186 16.3441 6.67687 17.6596 5.05425 19.7059L4.75025 20.0893L5.12694 20.4015C6.9929 21.9482 9.38866 22.8796 12 22.8796C14.6113 22.8796 17.0071 21.9482 18.8731 20.4015L19.2498 20.0893L18.9458 19.7059C17.3231 17.6596 14.814 16.3441 12 16.3441Z"/>

*/